/* CSS document -> App uses tailwindcss */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* sweet alert custom */
.swal2-popup.swal2-modal.swal2-show {
  width: auto !important;
}

.swal2-popup.swal2-modal.swal2-icon-error.swal2-show,
.swal2-popup.swal2-modal.swal2-icon-success.swal2-show {
  min-width: 150px !important;
}

div:where(.swal2-icon) {
  margin: 1.5em auto .6em !important;
}

div:where(.swal2-container).swal2-backdrop-show,
div:where(.swal2-container).swal2-noanimation {
  background: rgb(0 0 0 / 82%) !important;
}

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 68px !important;
  margin-left: 0;
  background: #f8f8f8 !important;
  border: 1px solid #adadad !important;
  border-radius: 0.5rem !important;
  line-height: 20px !important;
  height: 46px !important;
  width: 100% !important;
  outline: none;
  padding-top: 0.875rem !important;
  padding-bottom: 0.875rem !important;
  font-family: Poppins, sans-serif !important;
}

.dark .react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 68px !important;
  margin-left: 0;
  background: #1e293b !important;
  border: 1px solid #adadad !important;
  border-radius: 0.5rem !important;
  line-height: 20px !important;
  height: 46px !important;
  width: 100% !important;
  outline: none;
  padding-top: 0.875rem !important;
  padding-bottom: 0.875rem !important;
  font-family: Poppins, sans-serif !important;
  color: #d1d5db;
}

input,
select {
  height: 46px;
}

.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 55px !important;
  height: 100%;
  padding: 0 !important;
  border-radius: 0.5rem 0 0 0.5rem !important;
  justify-content: center;
  display: flex;
}

.dark .react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 55px !important;
  height: 100%;
  padding: 0 !important;
  border-radius: 0.5rem 0 0 0.5rem !important;
  justify-content: center;
  display: flex;
  background: #1e293b !important;
  border: 1px solid #adadad !important;
}

.dark .country-list {
  color:#000 !important;
}

.drop-box-shadow {
  box-shadow: 0px 1px 0px 0px #868686;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.custom-checkbox input[type="checkbox"] {
  display: none;
  /* Hide the default checkbox */
}

.custom-checkbox .checkmark {
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  /* Border color */
  border-radius: 2px;
  /* Rounded corners */
  position: relative;
  margin-right: 10px;
  /* Space between checkbox and label */
  background-color: white;
  /* Default background */
  transition: all 0.2s ease;
  /* Smooth transition for hover and focus */
}

.custom-checkbox:hover .checkmark {
  box-shadow: 0 0 0 13px rgba(0, 0, 0, 0.04);
  /* Add shadow on hover */
}

.custom-checkbox input[type="checkbox"]:checked+.checkmark {
  background-color: #8a3eb8;
  border-color: #8a3eb8;
  /* Change appearance when checked */
}

.custom-checkbox input[type="checkbox"]:checked+.checkmark:after {
  content: '';
  position: absolute;
  left: 6px;
  /* Adjust as needed */
  top: 2px;
  /* Adjust as needed */
  width: 5px;
  /* Adjust as needed */
  height: 10px;
  /* Adjust as needed */
  border: solid white;
  /* Checkmark color */
  border-width: 0 2px 2px 0;
  /* Create checkmark shape */
  transform: rotate(45deg);
  /* Rotate to form a checkmark */
}

/* Focus effects */
.custom-checkbox input[type="checkbox"]:focus+.checkmark {
  box-shadow: 0 0 0 13px rgba(0, 0, 0, 0.6);
  /* Add focus shadow */
}

.custom-checkbox input[type="checkbox"]:focus+.checkmark:before {
  opacity: 0.12;
  /* Focus effect for the pseudo-element */
}

/* Disabled state */
.custom-checkbox input[type="checkbox"]:disabled:checked+.checkmark {
  background-color: #c5bdca;
  border-color: #e0dde2;
}

.custom-checkbox input[type="checkbox"]:checked:focus+.checkmark {
  box-shadow: 0 0 0 13px #3b71ca;
  /* Add focus shadow on checked state */
}

.notification-pointer {
  display: block;
  background: #f89308;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  position: absolute;
  top: -3px;
  right: 2px;
  border: 2px solid #fff;
}

.rounded-link {
  background: #8a3eb8;
  padding: 20px;
  border-radius: 50px;
  color: #fff;
  position: absolute;
  top: -24px;
  left: calc(50% - 35.5px);
  box-shadow: 0px 1px 5px 1px #a258cd87;
  border: 3px solid #f2f2f2;
}
.dark .rounded-link {
  /* background: #7600bc;
  padding: 20px;
  border-radius: 50px;
  color: #fafafa;
  position: absolute;
  top: -24px;
  left: calc(50% - 35.5px);
  box-shadow: 0px 1px 5px 1px #a258cd87;
  border: 3px solid #e3e4e6; */
  background: #1e293b;
  padding: 20px;
  border-radius: 50px;
  color: #8a3eb8;
  position: absolute;
  top: -24px;
  left: calc(50% - 35.5px);
  box-shadow: 0px 1px 5px 1px #00000087;
  border: 3px solid #8a3eb8;
}

a.link-active>svg {
  fill: #8a3eb8 !important;
  color: #e5e7eb !important;
}

.dark a.link-active > svg {
  fill: #fff !important;
  color: #6e7b95 !important;
}

a.link-active>p {
  /* color:#7600bc !important; */
  font-weight: 700 !important;
}

.rounded-t-none {
  border-radius: 0 0 0 0 !important;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: #f5f5f5;
  border: 1px solid #cacaca;
  border-radius: 6px 0 0 6px;
}

